import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-hiddengame',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './hiddengame.component.html',
  styleUrl: './hiddengame.component.scss'
})
export class HiddengameComponent {
  score = 0;
  boxPosition = { top: '50%', left: '50%' };
  boxPosition2 = { top: '80%', left: '45%' };
  boxPosition3 = { top: '40%', left: '60%' };

  showSecondBox = false;
  showThirdBox = false;

  firstClickDetected = false;
  timeLeft: number = 30;
  interval: any;
  isGameOver = false;

  moveBox() {
    this.boxPosition = {
      top: Math.random() * 80 + '%',
      left: Math.random() * 80 + '%'
    };
  }

  moveBox2() {
    this.boxPosition2 = {
      top: Math.random() * 80 + '%',
      left: Math.random() * 80 + '%'
    };
  }

  moveBox3() {
    this.boxPosition3 = {
      top: Math.random() * 80 + '%',
      left: Math.random() * 80 + '%'
    };
  }

  checkScore() {
    if (this.score > 20) {
      this.showSecondBox = true;
    }
    if (this.score > 50) {
      this.showThirdBox = true;
    }
  }

  onClickBox() {
    if (!this.firstClickDetected) {
      this.firstClickDetected = true; 
      this.startTimer();
    }
    if (!this.isGameOver) {
      this.score++;
      this.moveBox();
      this.checkScore();
      
    }
  }

  onClickBox2() {
    if (!this.isGameOver) {
      this.score++;
      this.moveBox2();
      this.checkScore();
    }
  }

  onClickBox3() {
    if (!this.isGameOver) {
      this.score++;
      this.moveBox3();
      this.checkScore();
    }
  }

  startGame() {
    this.score = 0;
    this.timeLeft = 30;
    this.isGameOver = false;
    this.firstClickDetected = false;
    this.showSecondBox = false;
    this.showThirdBox = false;
    this.moveBox();
  }

  ngOnInit() {
    this.startGame();
    alert("Proficiat! Je vond het geheime spel, nu heb je tenminste iets te doen in afwachting van Parsidance.");
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.endGame();
      }
    }, 1000);
  }

  endGame() {
    this.isGameOver = true;
    clearInterval(this.interval);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
