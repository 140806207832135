<app-headerHomepage></app-headerHomepage>

<!-- <div class="parsidanceLogo">
    <a href="./">
    </a>
</div> -->

<a href="./">
    <img class="parsidanceLogo" src="../../assets/svg/Parsidance.svg">
</a>

<div class="thankyou">
    <img src="../../assets/img/homepage/cover.webp" alt="Cover Image Mainpage">
    <h1>
        THANK YOU<br>
        SEE YOU NEXT YEAR!
    </h1>

    <!-- <fa-icon [icon]="['far', 'circle-play']" size="5x" style="color: white" class="playButton"></fa-icon> -->
</div>

<!-- 
    <p-carousel class="custom-carousel" [value]="images" [numVisible]="1" [numScroll]="1" [circular]="true"
        [autoplayInterval]="4000">
        <ng-template let-image pTemplate="item">
            <img [src]="image.url" alt="Image description" style="width: 100%; height: auto;">
        </ng-template>
    </p-carousel>

    <div class="marquee-container">
        <span class="marquee-text">
            <ng-container *ngFor="let artist of artists;">
                <span class="artist-name">{{ artist }}</span>
            </ng-container>
        </span>
    </div>

    <p-carousel class="custom-carousel" [value]="logos" [numVisible]="8" [numScroll]="1" [circular]="true" [autoplayInterval]="2000">
    <ng-template let-logo pTemplate="item">
      <img [src]="logo.url" [alt]="logo.alt" style="display: block; margin: 0 auto; width: 100px; height: auto;">
    </ng-template>
  </p-carousel> 
-->

   
<!-- 
    <div class="countdown">
        <p class="countdown">Ticket sale starts in</p>
        <div id="flipdown" class="flipdown"></div>
    </div> 
-->

<!-- 
    <div class="scrollContainer">
        <p class="scrollText">
            Amber Broos&emsp;·&emsp;Pegassi&emsp;·&emsp;Helena Lauwaert&emsp;·&emsp;Flavour Drop&emsp;·&emsp;Eagl&emsp;·&emsp;Nina Black&emsp;·&emsp;Joyhauser&emsp;·&emsp;Dana Montana&emsp;·&emsp;Jeroen Delodder&emsp;·&emsp;Superstyling&emsp;·&emsp;séa&emsp;·&emsp;DJ SNS&emsp;·&emsp;DJ Tim&emsp;·&emsp;Huismus&emsp;·&emsp;Coolman&emsp;·&emsp;TDB&emsp;·&emsp;DJ Andrew&emsp;·&emsp;Monsieur Mathieu&emsp;·&emsp;Hafid&emsp;·&emsp;Yrd&emsp;·&emsp;Lolalita&emsp;·&emsp;Bavr&emsp;·&emsp;DC Noises&emsp;·&emsp;Low-E&emsp;·&emsp;Discobar Joossens ft. MC Spaghetti&emsp;·&emsp;Kwijt Trek System&emsp;·&emsp;Assia MK&emsp;·&emsp;Helsmoortel&emsp;·&emsp;Mr Majar&emsp;·&emsp;TDB&emsp;·&emsp;Remi-X&emsp;·&emsp;Van de Kaart Soundsystem&emsp;·&emsp;TitsnAss&emsp;·&emsp;Nuber&emsp;·&emsp;Cassievrucht&emsp;·&emsp;Mitras&emsp;·&emsp;Diskobar Sabrina&emsp;·&emsp;Dj.Germain&emsp;·&emsp;Kakbaard&emsp;·&emsp;Amazing Dré&emsp;·&emsp;DDD&emsp;·&emsp;Glenn Stefani&emsp;·&emsp;Paté en Patat&emsp;·&emsp;Pfeffer&emsp;·&emsp;Yrd&emsp;·&emsp;V=MCT²
        </p>
    </div>

    <div class="banner">
        <img src="../../assets/img/homepage/banner_mainstage.webp" alt="Cover Image Mainstage">
    </div>

    <div class="progressTickets">
        <p class="progressBarTitle">Friday sold out for</p>
        <div class="progressBarContainer">
            <div class="progressBar" [style.width.%]="progressFriday"></div>
            <div class="percentageLabel friday">{{progressFriday}}%</div>
        </div>

        <p class="progressBarTitle">Saturday sold out for</p>
        <div class="progressBarContainer">
            <div class="progressBar" [style.width.%]="progressSaturday"></div>
            <div class="percentageLabel saturday">{{progressSaturday}}%</div>            
        </div>
    </div>

    <div class="lineup">
        <a href="/line-up/friday" class="lineup">
            <img src="../../assets/img/lineup/lineup_vrij.webp" alt="Lineup Friday" class="lineup">
        </a>
        <a href="/line-up/saturday" class="lineup">
            <img href="/line-up/saturday" src="../../assets/img/lineup/lineup_zat.webp" alt="Lineup Saturday" class="lineup">
        </a>
    </div>
-->



<!--
    <div class="chevronDown" (click)="goToAftermovie()">
        <fa-icon [icon]="['fas', 'chevron-down']" animation="bounce" size="3x" style="color: white"></fa-icon>
    </div>

    <div id="aftermovie" class="aftermovie">       
        <video id="promovidSquare" class="aftermovie" [autoplay]=true [muted]=true [disableRemotePlayback]=true [playsInline]=true [loop]=true>
            <source src="../../assets/promoVids/promovid_square.mp4" type="video/mp4">
        </video>

        <video id="promovidVertical" class="aftermovie" [autoplay]=true [muted]=true [disableRemotePlayback]=true [playsInline]=true [loop]=true>
            <source src="../../assets/promoVids/promovid_vertical.mp4" type="video/mp4">
        </video>
    </div> 
-->

<app-footer></app-footer>