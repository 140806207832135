
<div id="a-z" class="artistContainer">
    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_Amazingdre.webp" loading="lazy" alt="Artist picture - Amazing Dré">
        <div class="name">
            <h2>Amazing Dré</h2>
            <h3><a href="/line-up/saturday#dixieland">Saturday | Dixieland | 18:30 - 19:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_AmberBroos.webp" loading="lazy" alt="Artist picture - Amber Broos">
        <div class="name">
            <h2>Amber Broos</h2>
            <h3><a href="/line-up/saturday#bunker">Saturday | Bunker | 21:00 - 22:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_Andrew.webp" loading="lazy" alt="Artist picture - Andrew">
        <div class="name">
            <h2>Andrew</h2>
            <h3><a href="/line-up/friday#dixie">Friday | Dixieland | 22:00 - 23:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_AssiaMK.webp" loading="lazy" alt="Artist picture - Assia MK">
        <div class="name">
            <h2>Assia MK</h2>
            <h3><a href="/line-up/saturday#main">Saturday | Mainstage | 17:00 - 18:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_BAVR.webp" loading="lazy" alt="Artist picture - BAVR">
        <div class="name">
            <h2>BAVR</h2>
            <h3><a href="/line-up/saturday#bunker">Saturday | Bunker | 17:30 - 18:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Cassievrucht.webp" loading="lazy" alt="Artist picture - Cassievrucht">
        <div class="name">
            <h2>Cassievrucht</h2>
            <h3><a href="/line-up/saturday#main">Saturday | Mainstage | 14:00 - 15:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_Coolman.webp" loading="lazy" alt="Artist picture - Coolman">
        <div class="name">
            <h2>Coolman</h2>
            <h3><a href="/line-up/friday#dixie">Friday | Dixieland | 23:00 - 00:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_DanaMontana.webp" loading="lazy" alt="Artist picture - Dana Montana">
        <div class="name">
            <h2>Dana Montana</h2>
            <h3><a href="/line-up/friday#bunker">Friday | Bunker | 20:15 - 21:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_DCNoises.webp" loading="lazy" alt="Artist picture - DC Noises">
        <div class="name">
            <h2>DC Noises</h2>
            <h3><a href="/line-up/saturday#bunker">Saturday | Bunker | 15:30 - 16:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Discobar.webp" loading="lazy" alt="Artist picture - Discobar Joossens ft. Mc Spaghetti">
        <div class="name">
            <h2>Discobar Joossens ft. Mc Spaghetti</h2>
            <h3><a href="/line-up/saturday#main">Saturday | Mainstage | 18:00 - 19:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_DiskobarSabrina.webp" loading="lazy" alt="Artist picture - Diskobar Sabrina">
        <div class="name">
            <h2>Diskobar Sabrina</h2>
            <h3><a href="/line-up/saturday#main">Saturday | Mainstage | 23:00 - 00:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_DJDDD.webp" loading="lazy" alt="Artist picture - DJ DDD">
        <div class="name">
            <h2>DJ DDD</h2>
            <h3><a href="/line-up/saturday#dixieland">Saturday | Dixieland | 19:30 - 20:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_DjSns.webp" loading="lazy" alt="Artist picture - Dj Sns">
        <div class="name">
            <h2>Dj Sns</h2>
            <h3><a href="/line-up/friday#dixieland">Friday | Dixieland | 16:00 - 17:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_DjTim.webp" loading="lazy" alt="Artist picture - Dj Tim">
        <div class="name">
            <h2>Dj Tim</h2>
            <h3><a href="/line-up/friday#dixieland">Friday | Dixieland | 20:30 - 22:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_DJ.Germain.webp" loading="lazy" alt="Artist picture - DJ. Germain">
        <div class="name">
            <h2>DJ. Germain</h2>
            <h3><a href="/line-up/saturday#bunker">Saturday | Bunker | 13:30 - 14:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Eagl.webp" loading="lazy" alt="Artist picture - Eagl">
        <div class="name">
            <h2>Eagl</h2>
            <h3><a href="/line-up/saturday#mainstage">Saturday | Mainstage | 20:00 - 21:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_FlavourDrop.webp" loading="lazy" alt="Artist picture - Flavour Drop">
        <div class="name">
            <h2>Flavour Drop</h2>
            <h3><a href="/line-up/saturday#mainstage">Saturday | Mainstage | 21:00 - 22:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_GlennStefani.webp" loading="lazy" alt="Artist picture - Glenn Stefani">
        <div class="name">
            <h2>Glenn Stefani</h2>
            <h3><a href="/line-up/saturday#dixieland">Saturday | Dixieland | 20:30 - 21:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_Hafid.webp" loading="lazy" alt="Artist picture - Hafid">
        <div class="name">
            <h2>Hafid</h2>
            <h3><a href="/line-up/friday#dixieland">Friday | Dixieland | 17:30 - 18:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_HelenaLauwaert.webp" loading="lazy" alt="Artist picture - Helena Lauwaert">
        <div class="name">
            <h2>Helena Lauwaert</h2>
            <h3><a href="/line-up/saturday#bunker">Saturday | Bunker | 19:45 - 21:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_Helsmoortel.webp" loading="lazy" alt="Artist picture - Helsmoortel">
        <div class="name">
            <h2>Helsmoortel</h2>
            <h3><a href="/line-up/saturday#bunker">Saturday | Bunker | 16:30 - 17:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_Huismus.webp" loading="lazy" alt="Artist picture - Huismus">
        <div class="name">
            <h2>Huismus</h2>
            <h3><a href="/line-up/friday#bunker">Friday | Bunker | 23:00 - 00:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_JeroenDelodder.webp" loading="lazy" alt="Artist picture - Jeroen Delodder">
        <div class="name">
            <h2>Jeroen Delodder</h2>
            <h3><a href="/line-up/friday#bunker">Friday | Bunker | 19:00 - 20:15</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_Joyhauser.webp" loading="lazy" alt="Artist picture - Joyhauser">
        <div class="name">
            <h2>Joyhauser</h2>
            <h3><a href="/line-up/friday#bunker">Friday | Bunker | 21:30 - 23:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_kakbaard.webp" loading="lazy" alt="Artist picture - Kakbaard">
        <div class="name">
            <h2>Kakbaard</h2>
            <h3><a href="/line-up/saturday#dixieland">Saturday | Dixieland | 15:30 - 16:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Kwijttreksystem.webp" loading="lazy" alt="Artist picture - Kwijt Trek System">
        <div class="name">
            <h2>Kwijt Trek System</h2>
            <h3><a href="/line-up/saturday#mainstage">Saturday | Mainstage | 22:00 - 23:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_Lolalita.webp" loading="lazy" alt="Artist picture - Lolalita">
        <div class="name">
            <h2>Lolalita</h2>
            <h3><a href="/line-up/saturday#bunker">Saturday | Bunker | 22:30 - 23:45</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_LowE.webp" loading="lazy" alt="Artist picture - Low E">
        <div class="name">
            <h2>Low E</h2>
            <h3><a href="/line-up/saturday#bunker">Saturday | Bunker | 23:45 - 01:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_MCDoubleU.webp" loading="lazy" alt="Artist picture - MC Double U">
        <div class="name">
            <h2>MC Double U</h2>
            <h3><a href="/line-up/friday#dixieland">Friday | Dixieland</a></h3>
            <h3><a href="/line-up/saturday#mainstage">Saturday | Mainstage</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Mitras.webp" loading="lazy" alt="Artist picture - Mitras">
        <div class="name">
            <h2>Mitras</h2>
            <h3><a href="/line-up/saturday#mainstage">Saturday | Mainstage | 15:00 - 16:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_MonsieurMathieu.webp" loading="lazy" alt="Artist picture - Monsieur Mathieu">
        <div class="name">
            <h2>Monsieur Mathieu</h2>
            <h3><a href="/line-up/friday#dixieland">Friday | Dixieland | 18:30 - 19:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_MrMajar.webp" loading="lazy" alt="Artist picture - Mr. Majar">
        <div class="name">
            <h2>Mr. Majar</h2>
            <h3><a href="/line-up/saturday#mainstage">Saturday | Mainstage | 00:00 - 01:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Ninablack.webp" loading="lazy" alt="Artist picture - Nina Black">
        <div class="name">
            <h2>Nina Black</h2>
            <h3><a href="/line-up/saturday#mainstage">Saturday | Mainstage | 19:00 - 20:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Nuber.webp" loading="lazy" alt="Artist picture - Nuber">
        <div class="name">
            <h2>Nuber</h2>
            <h3><a href="/line-up/saturday#mainstage">Saturday | Mainstage | 13:00 - 14:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_PateEnPatat.webp" loading="lazy" alt="Artist picture - Paté en Patat">
        <div class="name">
            <h2>Paté en Patat</h2>
            <h3><a href="/line-up/saturday#dixieland">Saturday | Dixieland | 14:30 - 15:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_Pegassi.webp" loading="lazy" alt="Artist picture - Pegassi">
        <div class="name">
            <h2>Pegassi</h2>
            <h3><a href="/line-up/saturday#bunker">Saturday | Bunker | 18:30 - 19:45</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_Pfeffer.webp" loading="lazy" alt="Artist picture - Pfeffer">
        <div class="name">
            <h2>Pfeffer</h2>
            <h3><a href="/line-up/saturday#bunker">Saturday | Bunker | 14:30 - 15:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_Remi-X.webp" loading="lazy" alt="Artist picture - Remi-X">
        <div class="name">
            <h2>Remi-X</h2>
            <h3><a href="/line-up/saturday#dixieland">Saturday | Dixieland | 21:30 - 22:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_sea.webp" loading="lazy" alt="Artist picture - séa">
        <div class="name">
            <h2>séa</h2>
            <h3><a href="/line-up/friday#bunker">Friday | Bunker | 17:00 - 18:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_Superstyling.webp" loading="lazy" alt="Artist picture - Superstyling">
        <div class="name">
            <h2>Superstyling</h2>
            <h3><a href="/line-up/friday#bunker">Friday | Bunker | 18:00 - 19:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_TDB.webp" loading="lazy" alt="Artist picture - TDB">
        <div class="name">
            <h2>TDB</h2>
            <h3><a href="/line-up/friday#dixieland">Friday | Dixieland | 19:30 - 20:30</a></h3>
            <h3><a href="/line-up/saturday#mainstage">Saturday | Mainstage | 16:00 - 17:00</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_TitsnAss.webp" loading="lazy" alt="Artist picture - TitsnAss">
        <div class="name">
            <h2>TitsnAss</h2>
            <h3><a href="/line-up/saturday#dixieland">Saturday | Dixieland | 16:30 - 17:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_v=mct.webp" loading="lazy" alt="Artist picture - V=MCT²">
        <div class="name">
            <h2>V=MCT²</h2>
            <h3><a href="/line-up/saturday#dixieland">Saturday | Dixieland | 22:30 - 23:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_VDK.webp" loading="lazy" alt="Artist picture - Van De Kaart Soundsystem">
        <div class="name">
            <h2>Van De Kaart Soundsystem</h2>
            <h3><a href="/line-up/saturday#dixieland">Saturday | Dixieland | 17:30 - 18:30</a></h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_Yrd.webp" loading="lazy" alt="Artist picture - Yrd">
        <div class="name">
            <h2>Yrd</h2>
            <h3><a href="/line-up/friday#bunker">Friday | Bunker | 16:00 - 17:00</a></h3>
        </div>
    </div>
</div>