
<h1 id="bunker" class="stageName">
    <img src="../../../assets/img/logos/bunker.png" alt="Bunker logo">
</h1>
<div id="a-z" class="artistContainer">
    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_Yrd.webp" alt="Artist picture - Yrd" loading="lazy">
        <div class="name">
            <h2>Yrd</h2>
            <h3>Friday | Bunker | 16:00 - 17:00</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_sea.webp" alt="Artist picture - séa" loading="lazy">
        <div class="name">
            <h2>séa</h2>
            <h3>Friday | Bunker | 17:00 - 18:00</h3>
        </div>
    </div>

    
    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_Superstyling.webp" alt="Artist picture - Superstyling" loading="lazy">
        <div class="name">
            <h2>Superstyling</h2>
            <h3>Friday | Bunker | 18:00 - 19:00</h3>
        </div>
    </div>

    
    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_JeroenDelodder.webp" alt="Artist picture - Jeroen Delodder" loading="lazy">
        <div class="name">
            <h2>Jeroen Delodder</h2>
            <h3>Friday | Bunker | 19:00 - 20:15</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_DanaMontana.webp" alt="Artist picture - Dana Montana" loading="lazy">
        <div class="name">
            <h2>Dana Montana</h2>
            <h3>Friday | Bunker | 20:15 - 21:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_Joyhauser.webp" alt="Artist picture - Joyhauser" loading="lazy">
        <div class="name">
            <h2>Joyhauser</h2>
            <h3>Friday | Bunker | 21:30 - 23:00</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Bunker/Vrij_Bunker_Huismus.webp" alt="Artist picture - Huismus" loading="lazy">
        <div class="name">
            <h2>Huismus</h2>
            <h3>Friday | Bunker | 23:00 - 00:00</h3>
        </div>
    </div>    
</div>

<h1 id="dixieland" class="stageName">
    <img src="../../../assets/img/logos/dixieland.png" alt="Dixieland logo">
</h1>
<div id="a-z" class="artistContainer">
    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_MCDoubleU.webp" alt="Artist picture - MC Double U" loading="lazy">
        <div class="name">
            <h2>MC Double U</h2>
            <h3>Friday | Dixieland</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_DjSns.webp" alt="Artist picture - Dj Sns" loading="lazy">
        <div class="name">
            <h2>Dj Sns</h2>
            <h3>Friday | Dixieland | 16:00 - 17:30</h3>
        </div>
    </div>
    
    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_Hafid.webp" alt="Artist picture - Hafid" loading="lazy">
        <div class="name">
            <h2>Hafid</h2>
            <h3>Friday | Dixieland | 17:30 - 18:30</h3>
        </div>
    </div>
    
    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_MonsieurMathieu.webp" alt="Artist picture - Monsieur Mathieu" loading="lazy">
        <div class="name">
            <h2>Monsieur Mathieu</h2>
            <h3>Friday | Dixieland | 18:30 - 19:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_TDB.webp" alt="Artist picture - TDB" loading="lazy">
        <div class="name">
            <h2>TDB</h2>
            <h3>Friday | Dixieland | 19:30 - 20:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_DjTim.webp" alt="Artist picture - Dj Tim" loading="lazy">
        <div class="name">
            <h2>Dj Tim</h2>
            <h3>Friday | Dixieland | 20:30 - 22:00</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_Andrew.webp" alt="Artist picture - Andrew" loading="lazy">
        <div class="name">
            <h2>Andrew</h2>
            <h3>Friday | Dixieland | 22:00 - 23:00</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Vrij_Dixie/Vrij_Dixie_Coolman.webp" alt="Artist picture - Coolman" loading="lazy">
        <div class="name">
            <h2>Coolman</h2>
            <h3>Friday | Dixieland | 23:00 - 00:00</h3>
        </div>
    </div>

    <div class="artist mobile">
    </div>
</div>