<div class="container saturday">
    <table class="timetable">
        <tr  style="border-top: 0;">
            <td class="hour" rowspan="2">13</td>
            <td class="empty"></td>
            <th>
                <img src="../../../assets/img/logos/mainstage.png" alt="Mainstage logo">
            </th>
            <td class="empty"></td>
            <th>
                <img src="../../../assets/img/logos/bunker.png" alt="Bunker logo">
            </th>
            <td class="empty"></td>
            <th>
                <img src="../../../assets/img/logos/dixieland.png" alt="Dixieland logo">
            </th>
            <td class="empty"></td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="mainstage" valign="top">
                <div class="artist">
                    13:00 - 14:00<br>
                    Nuber
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">14</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="bunker" valign="top">
                <div class="artist">
                    13:30 - 14:30<br>
                    Dj. Germain
                </div> 
            </td>
            
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="mainstage" valign="top">
                <div class="artist">
                    14:00 - 15:00<br>
                    Cassievrucht
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">15</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="bunker" valign="top">
                <div class="artist">
                    14:30 - 15:30<br>
                    Pfeffer
                </div>
            </td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie" valign="top">
                <div class="artist">
                    14:30 - 15:30<br>
                    Paté en Patat
                </div> 
            </td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="mainstage" valign="top">
                <div class="artist">
                    15:00 - 16:00<br>
                    Mitras
                </div>
            </td>
            <td class="empty"></td>
      
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">16</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="bunker" valign="top">
                <div class="artist" >
                    15:30 - 16:30<br>
                    DC Noises
                </div>
            </td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie" valign="top">
                <div class="artist">
                    15:30 - 16:30<br>
                    Kakbaard
                </div> 
            </td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="mainstage" valign="top">
                <div class="artist">
                    16:00 - 17:00<br>
                    TDB
                </div>
            </td>
            <td class="empty"></td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">17</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="bunker" valign="top">
                <div class="artist">
                    16:30 - 17:30<br>
                    Helsmoortel
                </div>
            </td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie" valign="top">
                <div class="artist">
                    16:30 - 17:30<br>
                    TitsnAss
                </div> 
            </td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="mainstage" valign="top">
                <div class="artist">
                    17:00 - 18:00<br>
                    Assia MK
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">18</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="bunker" valign="top">
                <div class="artist">
                    17:30 - 18:30<br>
                    BAVR
                </div>
            </td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie" valign="top">
                <div class="artist">
                    17:30 - 18:30<br>
                    Van de Kaart Soundsystem
                </div> 
            </td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="mainstage" valign="top">
                <div class="artist">
                    18:00 - 19:00<br>
                    Discobar Joossens ft. MC Spaghetti
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">19</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="bunker" valign="top">
                <div class="artist">
                    18:30 - 19:45<br>
                    Pegassi
                </div>
            </td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie" valign="top">
                <div class="artist">
                    18:30 - 19:30<br>
                    Amazing Dré
                </div> 
            </td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="mainstage" valign="top">
                <div class="artist">
                    19:00 - 20:00<br>
                    Nina Black
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">20</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="3" class="bunker" valign="top">
                <div class="artist">
                    19:45 - 21:00<br>
                    Helena Lauwaert
                </div>
            </td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie" valign="top">
                <div class="artist">
                    19:30 - 20:30<br>
                    DDD
                </div> 
            </td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="mainstage" valign="top">
                <div class="artist">
                    20:00 - 21:00<br>
                    Eagl
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">21</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie" valign="top">
                <div class="artist">
                    20:30 - 21:30<br>
                    Glenn Stefani
                </div> 
            </td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="mainstage" valign="top">
                <div class="artist">
                    21:00 - 22:00<br>
                    Flavour Drop
                </div>
            </td>
            <td class="empty"></td>
            <td rowspan="3" class="bunker" valign="top">
                <div class="artist">
                    21:00 - 22:30<br>
                    Amber Broos
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">22</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie" valign="top">
                <div class="artist">
                    21:30 - 22:30<br>
                    Remi-X
                </div> 
            </td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="mainstage" valign="top">
                <div class="artist">
                    22:00 - 23:00<br>
                    Kwijt Trek System
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">23</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="bunker"  valign="top">
                <div class="artist">
                    22:30 - 23:45<br>
                    Lolalita
                </div>
            </td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie" valign="top" >
                <div class="artist">
                    22:30 - 23:30<br>
                    V=MCT²
                </div> 
            </td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="mainstage" valign="top">
                <div class="artist">
                    23:00 - 00:00<br>
                    Diskobar Sabrina
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">00</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="3" class="bunker" valign="top">
                <div class="artist">
                    23:45 - 01:00<br>
                    Low E
                </div>
            </td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="mainstage" valign="top">
                <div class="artist">
                    00:00 - 01:00<br>
                    Mr Majar
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">01</td>
        </tr>

        <tr>
        </tr>
    </table>
</div>