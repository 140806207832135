<div class="container friday">
    <table class="timetable">
        <tr  style="border-top: 0;">
            <td class="hour" rowspan="2">16</td>
            <td class="empty"></td>
            <th>
                <img src="../../../assets/img/logos/bunker.png" alt="Bunker logo">
            </th>
            <td class="empty"></td>
            <th>
                <img src="../../../assets/img/logos/dixieland.png" alt="Dixieland logo">
            </th>
            <td class="empty"></td>
        </tr>

        <tr valign="top">
            <td class="empty"></td>
            <td rowspan="2" class="bunker">
                <div class="artist">
                    16:00 - 17:00<br>
                    Yrd
                </div>
            </td>
            <td class="empty"></td>
            <td rowspan="3" class="dixie">
                <div class="artist">
                    16:00 - 17:30<br>
                    DJ SNS
                </div> 
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">17</td>
            <td class="empty"></td>
        </tr>

        <tr valign="top">
            <td class="empty"></td>
            <td rowspan="2" class="bunker">
                <div class="artist">
                    17:00 - 18:00<br>
                    séa
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">18</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie"  valign="top">
                <div class="artist">
                    17:30 - 18:30<br>
                    Hafid
                </div>
            </td>
        </tr>

        <tr  valign="top">
            <td class="empty"></td>
            <td rowspan="2" class="bunker"  valign="top">
                <div class="artist">
                    18:00 - 19:00<br>
                    Superstyling
                </div>
            </td>
            <td class="empty"></td>
      
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">19</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie"  valign="top">
                <div class="artist" >
                    18:30 - 19:30<br>
                    Monsieur Mathieu
                </div>
            </td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="bunker" valign="top">
                <div class="artist">
                    19:00 - 20:15<br>
                    Jeroen Delodder
                </div>
            </td>
            <td class="empty"></td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">20</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie"  valign="top">
                <div class="artist">
                    19:30 - 20:30<br>
                    TDB
                </div>
            </td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="3" class="bunker" valign="top">
                <div class="artist">
                    20:15 - 21:30<br>
                    Dana Montana
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">21</td>
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="3" class="dixie"  valign="top">
                <div class="artist">
                    20:30 - 22:00<br>
                    DJ Tim
                </div>
            </td>
        </tr>

        <tr>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">22</td>
            <td class="empty"></td>
            <td rowspan="3" class="bunker"  valign="top">
                <div class="artist">
                    21:30 - 23:00<br>
                    Joyhauser
                </div>
            </td>
        </tr>

        <tr >
            <td class="empty"></td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie"  valign="top">
                <div class="artist">
                    22:00 - 23:00<br>
                    Andrew
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">23</td>
        </tr>

        <tr>
            <td class="empty"></td>
            <td rowspan="2" class="bunker"  valign="top">
                <div class="artist">
                    23:00 - 00:00<br>
                    Huismus
                </div>
            </td>
            <td class="empty"></td>
            <td rowspan="2" class="dixie"  valign="top">
                <div class="artist">
                    23:00 - 00:00<br>
                    Coolman
                </div>
            </td>
        </tr>

        <tr style="border-bottom: 0; border-top: 0;">
            <td class="hour" rowspan="2">00</td>
        </tr>

        <tr>
        </tr>
    </table>
</div>