<div class="topBar desktop">
    <div class="buttons">
        <div #openDeskButton>
            <fa-icon [icon]="['fas', 'bars']" size="2x" class="menuIcon"></fa-icon>
        </div>   
        <a href="./" class="parsidanceLogo">
            <img class="parsidanceLogo" src="../../assets/svg/Parsidance.svg">
        </a>
        <a href="" class="ticketButton"></a>
    </div >
    <hr class="topBar">
</div>

<div class="topBar mobile">
    <div class="buttons">
        <a href="./" class="parsidanceLogo">
            <img class="parsidanceLogo" src="../../assets/svg/Parsidance.svg">
        </a>
        <div #openMobButton>
            <fa-icon [icon]="['fas', 'bars']" size="2x" class="menuIcon"></fa-icon>
        </div>
    </div >
    <hr class="topBar">
</div>

<div class="topBarClose mobile">
    <div class="xIconContainer mobile">
        <div class="xIcon mobile" #closeMobButton>
            <fa-icon [icon]="['fas', 'xmark']" size="2x" class="xIcon"></fa-icon>   
        </div>
    </div>
</div>



<div id="menu" #menuDiv>
    <div class="xIcon desktop" #closeDeskButton>
        <fa-icon [icon]="['fas', 'xmark']" size="2x" class="xIcon"></fa-icon>   
    </div>

    <ul>
        <li><a href="info">INFO</a></li>
        <li><a href="line-up/a-z">LINE-UP</a></li>
        <li><a href="metdankaan">MET DANK AAN</a></li>
    </ul>  
    
    <div class="trademarkText">
        <a href="/">&copy; Parsidance Festival 2024</a>
    </div>
</div>