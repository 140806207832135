
<h1 id="mainstage" class="stageName">
    <img src="../../../assets/img/logos/mainstage.png" alt="Mainstage logo">
</h1>

<div id="a-z" class="artistContainer">
    
    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_MCDoubleU.webp" alt="Artist picture - MC Double U" loading="lazy">
        <div class="name">
            <h2>MC Double U</h2>
            <h3>Saturday | Mainstage</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Nuber.webp" alt="Artist picture - Nuber" loading="lazy">
        <div class="name">
            <h2>Nuber</h2>
            <h3>Saturday | Mainstage | 13:00 - 14:00</h3>
        </div>
    </div>
    
    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Cassievrucht.webp" alt="Artist picture - Cassievrucht" loading="lazy">
        <div class="name">
            <h2>Cassievrucht</h2>
            <h3>Saturday | Mainstage | 14:00 - 15:00</h3>
        </div>
    </div>

    
    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Mitras.webp" alt="Artist picture - Mitras" loading="lazy">
        <div class="name">
            <h2>Mitras</h2>
            <h3>Saturday | Mainstage | 15:00 - 16:00</h3>
        </div>
    </div>

    
    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_TDB.webp" alt="Artist picture - TDB" loading="lazy">
        <div class="name">
            <h2>TDB</h2>
            <h3>Saturday | Mainstage | 16:00- 17:00</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_AssiaMK.webp" alt="Artist picture - Assia MK" loading="lazy">
        <div class="name">
            <h2>Assia MK</h2>
            <h3>Saturday | Mainstage | 17:00 - 18:00</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Discobar.webp" alt="Artist picture - Discobar Joossens ft. Mc Spaghetti" loading="lazy">
        <div class="name">
            <h2>Discobar Joossens ft. Mc Spaghetti</h2>
            <h3>Saturday | Mainstage | 18:00 - 19:00</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Ninablack.webp" alt="Artist picture - Nina Black" loading="lazy">
        <div class="name">
            <h2>Nina Black</h2>
            <h3>Saturday | Mainstage | 19:00 - 20:00</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Eagl.webp" alt="Artist picture - Eagl" loading="lazy">
        <div class="name">
            <h2>Eagl</h2>
            <h3>Saturday | Mainstage | 20:00 - 21:00</h3>
        </div>
    </div>
    
    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_FlavourDrop.webp" alt="Artist picture - Flavour Drop" loading="lazy">
        <div class="name">
            <h2>Flavour Drop</h2>
            <h3>Saturday | Mainstage | 21:00 - 22:00</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_Kwijttreksystem.webp" alt="Artist picture - Kwijt Trek System" loading="lazy">
        <div class="name">
            <h2>Kwijt Trek System</h2>
            <h3>Saturday | Mainstage | 22:00 - 23:00</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_DiskobarSabrina.webp" alt="Artist picture - Diskobar Sabrina" loading="lazy">
        <div class="name">
            <h2>Diskobar Sabrina</h2>
            <h3>Saturday | Mainstage | 23:00 - 00:00</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Main/Zat_Main_MrMajar.webp" alt="Artist picture - Mr. Majar" loading="lazy">
        <div class="name">
            <h2>Mr. Majar</h2>
            <h3>Saturday | Mainstage | 00:00 - 01:00</h3>
        </div>
    </div>
</div>

<h1 id="bunker" class="stageName">
    <img src="../../../assets/img/logos/bunker.png" alt="Bunker logo">
</h1>
<div id="a-z" class="artistContainer">
    
    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_DJ.Germain.webp" alt="Artist picture - DJ. Germain" loading="lazy">
        <div class="name">
            <h2>DJ. Germain</h2>
            <h3>Saturday | Bunker | 13:30 - 14:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_Pfeffer.webp" alt="Artist picture - Pfeffer" loading="lazy">
        <div class="name">
            <h2>Pfeffer</h2>
            <h3>Saturday | Bunker 14:30 - 15:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_DCNoises.webp" alt="Artist picture - DC Noises" loading="lazy">
        <div class="name">
            <h2>DC Noises</h2>
            <h3>Saturday | Bunker| 15:30 - 16:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_Helsmoortel.webp" alt="Artist picture - Helsmoortel" loading="lazy">
        <div class="name">
            <h2>Helsmoortel</h2>
            <h3>Saturday | Bunker | 16:30 - 17:30</h3>
        </div>
    </div>
    
    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_BAVR.webp" alt="Artist picture - BAVR" loading="lazy">
        <div class="name">
            <h2>BAVR</h2>
            <h3>Saturday | Bunker | 17:30 - 18:30</h3>
        </div>
    </div>
    
    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_Pegassi.webp" alt="Artist picture - Pegassi" loading="lazy">
        <div class="name">
            <h2>Pegassi</h2>
            <h3>Saturday | Bunker | 18:30 - 19:45</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_HelenaLauwaert.webp" alt="Artist picture - Helena Lauwaert" loading="lazy">
        <div class="name">
            <h2>Helena Lauwaert</h2>
            <h3>Saturday | Bunker | 19:45 - 21:00</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_AmberBroos.webp" alt="Artist picture - Amber Broos" loading="lazy">
        <div class="name">
            <h2>Amber Broos</h2>
            <h3>Saturday | Bunker | 21:00 - 22:30</h3>
        </div>
    </div>   

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_Lolalita.webp" alt="Artist picture - Lolalita" loading="lazy">
        <div class="name">
            <h2>Lolalita</h2>
            <h3>Saturday | Bunker | 22:30 - 23:45</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Bunker/Zat_Bunker_LowE.webp" alt="Artist picture - Low E" loading="lazy">
        <div class="name">
            <h2>Low E</h2>
            <h3>Saturday | Bunker | 23:45 - 01:00</h3>
        </div>
    </div>
</div>

<h1 id="dixieland" class="stageName">
    <img src="../../../assets/img/logos/dixieland.png" alt="Dixieland logo">
</h1>
<div id="a-z" class="artistContainer">

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_PateEnPatat.webp" alt="Artist picture - Paté en Patat" loading="lazy">
        <div class="name">
            <h2>Paté en Patat</h2>
            <h3>Saturday | Dixieland | 14:30 - 15:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_kakbaard.webp" alt="Artist picture - Kakbaard" loading="lazy">
        <div class="name">
            <h2>Kakbaard</h2>
            <h3>Saturday | Dixieland | 15:30 - 16:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_TitsnAss.webp" alt="Artist picture - TitsnAss" loading="lazy">
        <div class="name">
            <h2>TitsnAss</h2>
            <h3>Saturday | Dixieland | 16:30 - 17:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_VDK.webp" alt="Artist picture - Van De Kaart Soundsystem" loading="lazy">
        <div class="name">
            <h2>Van De Kaart Soundsystem</h2>
            <h3>Saturday | Dixieland | 17:30 - 18:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_Amazingdre.webp" alt="Artist picture - Amazing Dré" loading="lazy">
        <div class="name">
            <h2>Amazing Dré</h2>
            <h3>Saturday | Dixieland | 18:30 - 19:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_DJDDD.webp" alt="Artist picture - DJ DDD" loading="lazy">
        <div class="name">
            <h2>DDD</h2>
            <h3>Saturday | Dixieland | 19:30 - 20:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_GlennStefani.webp" alt="Artist picture - Glenn Stefani" loading="lazy">
        <div class="name">
            <h2>Glenn Stefani</h2>
            <h3>Saturday | Dixieland | 20:30 - 21:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_Remi-X.webp" alt="Artist picture - Remi-X" loading="lazy">
        <div class="name">
            <h2>Remi-X</h2>
            <h3>Saturday | Dixieland | 21:30 - 22:30</h3>
        </div>
    </div>

    <div class="artist">
        <img src="../../assets/img/artists/Zat_Dixie/Zat_Dixie_v=mct.webp" alt="Artist picture - V=MCT²" loading="lazy">
        <div class="name">
            <h2>V=MCT²</h2>
            <h3>Saturday | Dixieland | 22:30 - 23:30</h3>
        </div>
    </div>
</div>