<app-header></app-header>

<div class="selectTab">
    <h1>MET DANK AAN</h1>
    <hr>
</div>


<!--
logo's transparant dus geen witte achtergrond<
geen zwart in het logo
logo's gerangschikt volgens meest betaald
logo's goeie afmetingen (goeie kwaliteit)
-->

<div id="spnsrs" class="spnsrContainer">
    <div class="spnsr">
        <a class="spnsr-link" href="https://www.hxgn.be/" target="_blank">
            <img src="../../assets/img/sponsors/Hexagon.svg" alt="logo Hexagon" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.aluwood.be/" target="_blank">
            <img src="../../assets/img/sponsors/Aluwood.webp" alt="logo Aluwood" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://wearebomber.be/" target="_blank">
            <img src="../../assets/img/sponsors/Bomber.svg" alt="logo Bomber" loading="lazy">
        </a>
    </div>
    
    <div class="spnsr">
        <a class="spnsr-link" href="https://www.hannibal.be/nl" target="_blank">
            <img src="../../assets/img/sponsors/Hannibal.svg" alt="logo Hannibal" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.maselis.be/" target="_blank">
            <img src="../../assets/img/sponsors/Maselis.webp" alt="logo Maselis" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.beeuwsaert-construct.be/nl" target="_blank">
            <img src="../../assets/img/sponsors/Beeuwsaert.svg" alt="logo Beeuwsaert" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.cayseele.be/" target="_blank">
            <img src="../../assets/img/sponsors/Cayseele.png" alt="logo Cayseele" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.slashagency.be/" target="_blank">
            <img src="../../assets/img/sponsors/SlashAgency.png" alt="logo Slash Agency" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.titeca.be/en/" target="_blank">
            <img src="../../assets/img/sponsors/TitecaAccountancy.jpg" alt="logo Titeca Accountancy" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.optieklunette.be/" target="_blank">
            <img src="../../assets/img/sponsors/lunette.webp" alt="logo Lunette" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.maslo.be/" target="_blank">
            <img src="../../assets/img/sponsors/maslo.png" alt="logo Maslo" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.garagehaverbeke.be/" target="_blank">
            <img src="../../assets/img/sponsors/Haverbeke.png" alt="logo Maslo" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.brouwerijdebrabandere.be/nl" target="_blank">
            <img src="../../assets/img/sponsors/brdb.png" alt="logo Brouwerij de Brabandere" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.brouwerijdebrabandere.be/nl" target="_blank">
            <img src="../../assets/img/sponsors/Bavik.jpg" alt="logo Bavik" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.bargiraf.be/" target="_blank">
            <img src="../../assets/img/sponsors/BarGiraf.png" alt="logo Bar Giraf" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.renoforce.be/" target="_blank">
            <img src="../../assets/img/sponsors/RenoforceWithoutBackground.png" alt="logo Renoforce"  loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://inthelead.be/" target="_blank">
            <img src="../../assets/img/sponsors/InTheLead.svg" alt="logo In The Lead" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://esdbvba.be/" target="_blank">
            <img src="../../assets/img/sponsors/ESD.png" alt="logo ESD" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.tecjobz.be/" target="_blank">
            <img src="../../assets/img/sponsors/tecjobz.svg" alt="logo tecjobz" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://ixina.be/nl/" target="_blank">
            <img src="../../assets/img/sponsors/Ixina.svg" alt="logo Ixina" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.olijf.be/" target="_blank">
            <img src="../../assets/img/sponsors/Olijf.png" alt="logo Olijf" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.bofisc.be/" target="_blank">
            <img src="../../assets/img/sponsors/Bofisc.svg" alt="logo Bofisc" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.congristruct.be/" target="_blank">
            <img src="../../assets/img/sponsors/Congristruct.webp" alt="logo Congristruct" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://groepdgb.be/" target="_blank">
            <img src="../../assets/img/sponsors/GroepDGB.svg" alt="logo Groep DGB" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.floorify.com/" target="_blank">
            <img src="../../assets/img/sponsors/Floorify.webp" alt="logo Floorify" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.idelco.be/" target="_blank">
            <img src="../../assets/img/sponsors/Idelco.jpeg" alt="logo Idelco" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://dejonckheere-roeselare.bmw.be/" target="_blank">
            <img src="../../assets/img/sponsors/Dejonckheere.webp" alt="logo Dejonckheere" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.buroplancke.be/" target="_blank">
            <img src="../../assets/img/sponsors/BuroPlancke.png" alt="logo Buro Plancke" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.advocam.be/" target="_blank">
            <img src="../../assets/img/sponsors/Camerlynck-logo-CMYK.png" alt="logo Camerlynck & Camerlynck" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" target="_blank">
            <img src="../../assets/img/sponsors/moustache.webp" alt="logo café Moustache" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.dezondag.be/" target="_blank">
            <img src="../../assets/img/sponsors/DeZondag.png" alt="logo De Zondag" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://tsl-systems.be/services" target="_blank">
            <img src="../../assets/img/sponsors/TSL.jpg" alt="logo TSL" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.muldernaturalfoods.com/en" target="_blank">
            <img src="../../assets/img/sponsors/Mulder.png" alt="logo Mulder" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://marker.be/" target="_blank">
            <img src="../../assets/img/sponsors/marker.svg" alt="logo marker" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.funnycastle.be/" target="_blank">
            <img src="../../assets/img/sponsors/FunnyCastle.png" alt="logo Funny Castle" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://sijs.be/nl/Home" target="_blank">
            <img src="../../assets/img/sponsors/Sijs.webp" alt="logo Sijs" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.kinevandecappelle.be/" target="_blank">
            <img src="../../assets/img/sponsors/Vandecappelle.png" alt="logo marker" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.uniek.org/nl" target="_blank">
            <img src="../../assets/img/sponsors/UNIE-K.png" alt="logo Unie-K" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.optiekvanneste.be/" target="_blank">
            <img src="../../assets/img/sponsors/OptiekVanNeste.png" alt="logo Optiek Van Neste" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.kantoorverstraete.be/nl-be/node/1" target="_blank">
            <img src="../../assets/img/sponsors/KantoorVerstraete.png" alt="logo Kantoor Verstraete" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.anamcara-thuisverpleging.be/" target="_blank">
            <img src="../../assets/img/sponsors/AnamCara.webp" alt="logo Anam Cara" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://agristo.com/nl" target="_blank">
            <img src="../../assets/img/sponsors/Agristo.svg" alt="logo Agristo" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.qenopauwels.be/" target="_blank">
            <img src="../../assets/img/sponsors/Qenopauwels.webp" alt="logo QenO Pauwels" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://kw.be/" target="_blank">
            <img src="../../assets/img/sponsors/kw.svg" alt="logo De krant van west Vlaanderen" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.belistate.be/node/1" target="_blank">
            <img src="../../assets/img/sponsors/Belistate.svg" alt="logo Belistate" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.autobedrijfvankeirsbilck.be/index.php" target="_blank">
            <img src="../../assets/img/sponsors/vankeirsbilck_logo.png" alt="logo Belistate" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.dlpa.be/nl" target="_blank">
            <img src="../../assets/img/sponsors/dlpa_logo.svg" alt="logo DPLA" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="http://www.groepdevos.be/" target="_blank">
            <img src="../../assets/img/sponsors/Devos.gif" alt="logo Devos" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.sofieverhaeghe.be/" target="_blank">
            <img src="../../assets/img/sponsors/ApotheekSofieVerhaeghe.png" alt="logo Apotheek Sofie Verhaeghe" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.kbc.be/particulieren/nl/kantoor/3043-ROESELARE-OEKENSESTRAAT-9.html" target="_blank">
            <img src="../../assets/img/sponsors/KBC.svg" alt="logo KBC" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://sleeplife.be/nl/winkels/sleeplife-roeselare" target="_blank">
            <img src="../../assets/img/sponsors/Sleeplife.svg" alt="logo Sleeplife" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.roeselare.be/" target="_blank">
            <img src="../../assets/img/sponsors/RSL.png" alt="logo Roeselare" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.expertsingifts.com/" target="_blank">
            <img src="../../assets/img/sponsors/jasa.png" alt="logo jasa" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://www.broodenbanketgeldhof.be/" target="_blank">
            <img src="../../assets/img/sponsors/Geldhof.webp" alt="logo Geldhof" loading="lazy">
        </a>
    </div>

    <div class="spnsr">
        <a class="spnsr-link" href="https://airvisuals.be/" target="_blank">
            <img src="../../assets/img/sponsors/Airvisuals.png" alt="logo Airvisuals" loading="lazy">
        </a>
    </div>
    
    <div class="spnsr">
        <a class="spnsr-link" href="https://www.voestalpine.com/sadef/" target="_blank">
            <img src="../../assets/img/sponsors/sadef.png" alt="logo Sadef" loading="lazy">
        </a>
    </div>
    
    <div class="spnsr">
        <a class="spnsr-link" href="https://nsdeco.be/" target="_blank">
            <h2>NS Deco</h2>
        </a>
    </div>


    <div class="spnsr">
        <h2>D. Silicone Moulds</h2>
    </div>
</div>

<app-footer></app-footer>


<!--
NOG TOE TE VOEGEN:
Camerlynck & Camerlynck
Kapoentje
Rovel
Notaris Kathleen van den eynde
-->