<app-header></app-header>

<div class="privacyPolicy">
    <h1 class="privacyPolicy">PRIVACY POLICY</h1>
    Het Festival Parsidance hecht veel waarde aan de bescherming van uw privacy. 
    Dit privacybeleid beschrijft hoe wij persoonlijke informatie verzamelen, gebruiken, 
    openbaar maken en beschermen die we ontvangen via onze website, services, ticketverkoop, 
    en alle andere activiteiten die gerelateerd zijn aan het Festival Parsidance.
    
    <h2>Verzamelde informatie</h2>
    Wij verzamelen verschillende soorten informatie voor diverse doeleinden om onze service 
    aan u te verbeteren. De soorten persoonlijke informatie die we kunnen verzamelen zijn onder 
    andere:
    <ul class="privacyPolicy">
        <li class="privacyPolicy">Contactinformatie zoals naam, e-mailadres, telefoonnummer, en postadres.</li>
        <li class="privacyPolicy">Demografische informatie zoals leeftijd, geslacht, en interesses (optioneel).</li>
        <li class="privacyPolicy">Informatie die u vrijwillig deelt tijdens de interactie met ons, zoals enquêtes of feedback.</li>
    </ul>

    <h2>Gebruik van informatie</h2>
    De informatie die we verzamelen, kan worden gebruikt voor:
    <ul class="privacyPolicy">
        <li class="privacyPolicy">Het verwerken van ticketaankopen en het bieden van de gevraagde diensten.</li>
        <li class="privacyPolicy">Het verbeteren van onze diensten en website op basis van uw feedback.</li>
        <li class="privacyPolicy">Het verstrekken van informatie over het Festival Parsidance, gerelateerde evenementen, nieuwsbrieven,
            en aanbiedingen waarvan wij denken dat ze interessant voor u kunnen zijn.</li>
        <li class="privacyPolicy">Het voldoen aan wettelijke verplichtingen en het beschermen tegen frauduleuze activiteiten.</li>
    </ul>

    <h2>Delen van informatie</h2>
    Wij delen uw persoonlijke informatie niet met derden, tenzij dit noodzakelijk is voor het verlenen van onze diensten, om te voldoen 
    aan de wet, om onze rechten te beschermen, of met uw uitdrukkelijke toestemming.
    
    <h2>Beveiliging van informatie</h2>
    Wij nemen redelijke maatregelen om uw persoonlijke informatie te beschermen tegen verlies, misbruik, ongeoorloofde toegang, 
    openbaarmaking, wijziging of vernietiging.

    <h2>Uw rechten</h2>
    U heeft het recht om toegang te krijgen tot uw persoonlijke informatie die wij bewaren en om deze te corrigeren, bij te werken of te verwijderen 
    indien nodig. U kunt ook bezwaar maken tegen het gebruik van uw informatie voor direct marketingdoeleinden.

    <h2>Wijzigingen in dit privacybeleid</h2>
    Dit privacybeleid kan van tijd tot tijd worden bijgewerkt. Wij zullen de datum van de laatste update bovenaan dit beleid aanpassen. Wij raden u 
    aan om dit beleid regelmatig te controleren op eventuele wijzigingen.

    <h2>Contact opnemen</h2>
    Als u vragen heeft over dit privacybeleid, uw informatie of onze praktijken, neem dan gerust contact met ons op via <a href="mailto:info@parsidance.be" class="privacyPolicy">info&#64;parsidance.be</a>
    <br><br>
    <i>Laatst bijgewerkt op 21.12.2023</i>
</div>

<app-footer></app-footer>
