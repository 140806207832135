import { Component } from '@angular/core';

@Component({
  selector: 'app-friday',
  templateUrl: './friday.component.html',
  styleUrls: ['./friday.component.scss']
})
export class FridayLineupComponent {

}
