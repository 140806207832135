<app-header></app-header>

<div class="verkoopsvoorwaarden">
    <h1 class="verkoopsvoorwaarden">Verkoopsvoorwaarden</h1>

    <ol class="verkoopsvoorwaarden">
        <li class="verkoopsvoorwaarden">
            <u>Leeftijdsbeperking</u>: Parsidance Festival staat alleen toe dat bezoekers van 18 jaar en ouder het evenement bijwonen. Personen jonger dan 18 jaar zullen niet worden toegelaten tot het festivalterrein.
        </li>

        <li class="verkoopsvoorwaarden">
            <u>Terugbetaling</u>: Indien een persoon jonger dan 18 jaar op de dag van het festival een ticket heeft gekocht, wordt geen restitutie verleend. Het is de verantwoordelijkheid van de koper om te controleren of ze voldoen aan de leeftijdsvereisten voordat ze een ticket aanschaffen.
        </li>

        <li class="verkoopsvoorwaarden">
            <u>Datum en Locatie</u>: Parsidance Festival vindt plaats op vrijdag 12 en zaterdag 13 april 2024 op het terrein gelegen aan Koestraat 20, 8800 Rumbeke.
        </li>

        <li class="verkoopsvoorwaarden">
            <u>Toegang tot het festival</u>: Toegang tot het festivalterrein is alleen toegestaan voor personen van 18 jaar en ouder. Identificatie kan worden gevraagd bij de ingang.
        </li>

        <li class="verkoopsvoorwaarden">
            <u>Ticketaankoop</u>: Het kopen van een ticket impliceert dat de koper akkoord gaat met de verkoopsvoorwaarden van Parsidance Festival.
        </li>

        <li class="verkoopsvoorwaarden">
            <u>Wijzigingen in het programma</u>: De organisatoren behouden zich het recht voor om wijzigingen aan te brengen in het programma, inclusief artiesten, tijden en locaties, zonder voorafgaande kennisgeving. Dit geeft de koper niet automatisch recht op restitutie.
        </li>

        <li class="verkoopsvoorwaarden">
            <u>Annulering van het festival</u>: In het geval van annulering van het festival door onvoorziene omstandigheden, zal er een terugbetaling van de ticketkosten plaatsvinden, met uitzondering van eventuele servicekosten.
        </li>

        <li class="verkoopsvoorwaarden">
            <u>Veiligheid en gedrag</u>: Veiligheid en gedrag: Parsidance Festival behoudt zich het recht voor om personen die zich misdragen of de veiligheid van anderen in gevaar brengen, de toegang te ontzeggen of uit het terrein te verwijderen zonder restitutie.
        </li>
    </ol>

    <p class="verkoopsvoorwaarden">
        Door een ticket te kopen voor Parsidance Festival, stemt de koper in met bovenstaande verkoopsvoorwaarden.
        <br><br>
        Voor vragen of aanvullende informatie kunt u contact met ons opnemen via <a href="mailto:info@parsidance.be" class="verkoopsvoorwaarden">info&#64;parsidance.be</a>
    </p>
</div>

<app-footer></app-footer>