<app-header></app-header>

<div class="selectTab">
    <div class="headLinks">
        <a routerLink="/line-up" routerLinkActive="active-link"><h1 id="lineup">LINE-UP</h1></a>
        <a routerLink="/timetable" routerLinkActive="active-link"><h1 id="timetable">TIMETABLE</h1></a>
    </div>
        
    <ul>
        <li id="az"><a routerLink="/line-up/a-z" routerLinkActive="active-link">A-Z</a></li>
        <li id="friday"><a routerLink="/line-up/friday" routerLinkActive="active-link">Friday 12.04</a></li>
        <li id="saturday"><a routerLink="/line-up/saturday" routerLinkActive="active-link">Saturday 13.04</a></li>
    </ul>
    <hr>
</div>

<router-outlet></router-outlet>

<app-footer></app-footer>