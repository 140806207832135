<div class="game-container">
    <img class="parsidanceLogo" src="../../assets/svg/Parsidance.svg">
    <p class="score" *ngIf="!isGameOver">Score: {{ score }}</p>
    <p *ngIf="isGameOver">Game Over! Final Score: {{ score }}</p>
    <p class="timer">Time: {{ timeLeft }} seconds</p>
    <div class="game-area" *ngIf="!isGameOver">
      <div class="box" [style.top]="boxPosition.top" [style.left]="boxPosition.left" (click)="onClickBox()"></div>
      <div *ngIf="showSecondBox" class="box" [style.top]="boxPosition2.top" [style.left]="boxPosition2.left" (click)="onClickBox2()"></div>
      <div *ngIf="showThirdBox" class="box" [style.top]="boxPosition3.top" [style.left]="boxPosition3.left" (click)="onClickBox3()"></div>
    </div>
    <button *ngIf="isGameOver" class="play-again-button" (click)="startGame()">Play Again</button>

    <button *ngIf="isGameOver" class="home-button" [routerLink]="'/'"> Homepage</button>
  </div>
  