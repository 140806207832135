<div class="topBar desktop">
    <div class="buttons">
        <div #openDeskButton>
            <fa-icon [icon]="['fas', 'bars']" size="2x" class="menuIcon"></fa-icon>
        </div>  
        <!-- To keep horizLine at same positon as other header (space of PD-logo) -->
        <div class="invisibleSpace"></div>
        <!-- <a href="https://tickets.parsidance.be" class="ticketButton">TICKETS</a> -->
    </div >
    <hr class="topBar">
</div>

<div class="topBar mobile">
    <div class="buttons">
        <!-- <a href="https://tickets.parsidance.be" class="ticketButton">TICKETS</a> -->
        <div class="invisibleSpace"></div>
        <div #openMobButton class="openMobButton">
            <fa-icon [icon]="['fas', 'bars']" size="2x" class="menuIcon"></fa-icon>
        </div>
        <!-- To keep horizLine at same positon as other header (space of PD-logo) -->
    </div >
    <hr class="topBar">
</div>

<div class="topBarClose mobile">
    <div class="closeContainer">
        <div class="xIcon mobile" #closeMobButton>
            <fa-icon [icon]="['fas', 'xmark']" size="2x" class="xIcon"></fa-icon>   
        </div>
    </div>
    <hr>
</div>

<!-- <div class="parsidanceLogo">
    <a href="./">
        <img class="parsidanceLogo" src="../../assets/svg/Parsidance.svg">
    </a>
    <p class="subtitleLogo">Friday 12.04.2024 - Saturday 13.04.2024</p>
</div> -->

<div id="menu" #menuDiv>
    <div class="xIcon" #closeDeskButton >
        <fa-icon [icon]="['fas', 'xmark']" size="2x" class="xIcon"></fa-icon>    
    </div>
    <ul>
        <li><a href="info">INFO</a></li>
        <li><a href="line-up/a-z">LINE-UP</a></li>
        <li><a href="metdankaan">MET DANK AAN</a></li>
    </ul>  
    
    <div class="trademarkText">
        <a href="/">&copy; Parsidance Festival 2024</a>
    </div>
</div>