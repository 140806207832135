import { Component } from '@angular/core';

@Component({
  selector: 'app-az',
  templateUrl: './az.component.html',
  styleUrls: ['./az.component.scss']
})
export class AzLineupComponent {

}
