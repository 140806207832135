import { Component } from '@angular/core';

@Component({
  selector: 'app-verkoopsvoorwaarden',
  templateUrl: './verkoopsvoorwaarden.component.html',
  styleUrls: ['./verkoopsvoorwaarden.component.scss']
})
export class VerkoopsvoorwaardenComponent {

}
